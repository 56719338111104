import React from "react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import {
  faCalculator,
  faArchive,
  faTasks,
  faPaste,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../layouts/layout"
import SEO from "../layouts/seo"

const FeaturesPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title={t("navigation.features")} />
      <article className="article text--center">
        <header className="article-header">
          <h1 className="heading heading--md">{t("features.header")}</h1>
          <p className="paragraph paragraph--lg">
            <Trans>features.header-paragraph</Trans>
          </p>
        </header>

        <ul className="features-list">
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faCalculator} />
            </div>
            <h2>
              <Trans>features.item-header-1</Trans>
            </h2>
            <ol>
              <li>
                <Trans>features.item-1-1</Trans>
              </li>
              <li>
                <Trans>features.item-1-2</Trans>
              </li>
              <li>
                <Trans>features.item-1-3</Trans>
              </li>
              <li>
                <Trans>features.item-1-4</Trans>
              </li>
              <li>
                <Trans>features.item-1-5</Trans>
              </li>
            </ol>
          </li>

          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faArchive} />
            </div>
            <h2>
              <Trans>features.item-header-2</Trans>
            </h2>
            <ol>
              <li>
                <Trans>features.item-2-1</Trans>
              </li>
              <li>
                <Trans>features.item-2-2</Trans>
              </li>
              <li>
                <Trans>features.item-2-3</Trans>
              </li>
            </ol>
          </li>

          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faTasks} />
            </div>
            <h2>
              <Trans>features.item-header-3</Trans>
            </h2>
            <ol>
              <li>
                <Trans>features.item-3-1</Trans>
              </li>
              <li>
                <Trans>features.item-3-2</Trans>
              </li>
              <li>
                <Trans>features.item-3-3</Trans>
              </li>
            </ol>
          </li>

          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faPaste} />
            </div>
            <h2>
              <Trans>features.item-header-4</Trans>
            </h2>
            <ol>
              <li>
                <Trans>features.item-4-1</Trans>
              </li>
              <li>
                <Trans>features.item-4-2</Trans>
              </li>
            </ol>
          </li>
        </ul>
      </article>
    </Layout>
  )
}
export default FeaturesPage
